body {
  font: 12px "Century Gothic", Futura, sans-serif;
  margin: 0px;
  background-color: black;
}

.fixed1{
display: flex;
justify-content: space-between;
padding:20px 20px 0px 20px; 
position:fixed; 
width:90%; 
height: 40px; 
z-index:4;
cursor: pointer;
background-color:black;
}

.fixed2{
display: flex;
justify-content: space-between;
padding:65px 20px 0px 20px; 
position:fixed; 
width:90%; 
height: 30px; 
cursor: pointer;
z-index:3;
background-color:black;
}

.fixed3{
display: flex;
justify-content: space-between;
padding:0px 20px 20px 20px; 
position:fixed; 
bottom:0;
width:90%; 
height: 250px; 
cursor: pointer;
background-color:black;
z-index:3;

}

.navbtn{
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 12px;
  width: 10ch;
  height: 30px;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
  background-color: darkcyan;
  border: darkcyan;
  color: white;
  }

.firstgroup{
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 95%;
  height: 100%;
  padding: 0px 20px 20px 20px;
}

.mytitle {
  font-family: "Century Gothic", Futura, sans-serif;
  color: rgb(137, 139, 250);
  font-size: 20px;
  white-space: nowrap;
}

.smartname {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-bottom: 20px;
}

.checkandname {
  width: 45%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.label {
  font-size: 22px;
  transition: color 0.2s;
}

.valuetextbox {
  width:  55%;
}
.valueinput {
  font-size: 20px;
  width: 100%;
}

.logbox {
  border: solid;
  height: 180px;
  margin: 15px 15px 15px 15px;
  padding: 15px;
  overflow-y: scroll;
}

.textlog {
  font-size: 20px;
  height: 200px;
  width: 95%;
  margin-top: 20px;
}

